@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  @apply font-sig
}

@layer base {
  h1 {
    @apply text-3xl
  }

  h2 {
    @apply text-2xl
  }
}